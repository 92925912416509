import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUserProfile, UserProfile } from 'services/APIs';
import { LOADING_STATUS, AUTH_SYS_TYPE } from 'utils/constants/common';
import {
  saveAuthToken,
  loadAuthToken,
  removeAuthToken,
  removeSocialUserData,
  loadOtpToken,
} from 'utils/storage';
import { RootState } from './store';

export interface SocialData {
  email: string;
  firstName: string;
  lastName: string;
  userToken: string;
  dob?: string;
  phoneNumber?: string;
}

interface ISetupAccountResponse {
  email: string;
}

interface IResetPasswordResponse {
  email: string;
}

interface UserInfo {
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
  phoneNumber: string;
  password: string;
  sysType: string;
  userToken?: string;
}

interface IAuthenticationResponse {
  accessToken: string;
  refreshToken: string;
  profile: UserProfile;
}

interface IUpdateSysTypePayload {
  socialData: SocialData;
  sysType: string;
}

const setupAccount = async ({
  token,
  email,
  password,
}: {
  token: string;
  email: string;
  password: string;
}) => {
  const response = await axios.post<ISetupAccountResponse>(
    'user/auth/setup-account',
    {
      token,
      email,
      password,
    },
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};

const resetPassword = async ({
  token,
  email,
  password,
}: {
  token: string;
  email: string;
  password: string;
}) => {
  const response = await axios.post<IResetPasswordResponse>(
    'user/auth/reset-password',
    {
      token,
      email,
      password,
    },
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await axios.post<
    IAuthenticationResponse | { isVerified: boolean }
  >(
    'user/auth/login',
    {
      email,
      password,
      sysType: AUTH_SYS_TYPE.PASSWORD,
    },
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    }
  );

  return response.data;
};

const signup = async (userInfo: UserInfo) => {
  const response = await axios.post<IAuthenticationResponse>(
    '/user/auth/sign-up',
    userInfo,
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      headers: {
        'X-Auth': 'otp',
        'X-Authorization': `JWT ${loadOtpToken(userInfo.phoneNumber)}`,
      },
    }
  );
  return response.data;
};

const logout = async () => {
  const refreshToken = loadAuthToken()?.refreshToken;
  const response = await axios.post('user/auth/logout', null, {
    baseURL: process.env.REACT_APP_AXIOS_BE_URL,
    headers: {
      Authorization: `JWT ${refreshToken}`,
    },
  });
  return response.data;
};

const loginWithSocial = async ({
  email,
  sysType,
  userToken,
}: {
  email: string;
  sysType: string;
  userToken: string;
}) => {
  const response = await axios.post<IAuthenticationResponse>(
    'user/auth/login',
    {
      email,
      sysType,
    },
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      headers: { Authorization: `JWT ${userToken}` },
    }
  );

  return response.data;
};

const signUpWithSocial = async ({
  email,
  firstName,
  lastName,
  dob,
  phoneNumber,
  sysType,
  userToken,
}: {
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
  phoneNumber: string;
  sysType: string;
  userToken: string;
}) => {
  const response = await axios.post<IAuthenticationResponse>(
    'user/auth/sign-up',
    {
      email,
      firstName,
      lastName,
      dob,
      phoneNumber,
      password: '',
      sysType,
    },
    {
      baseURL: process.env.REACT_APP_AXIOS_BE_URL,
      headers: {
        Authorization: `JWT ${userToken}`,
        'X-Auth': 'otp',
        'X-Authorization': `JWT ${loadOtpToken(phoneNumber)}`,
      },
    }
  );

  return response.data;
};

export const setupAccountThunk = createAsyncThunk(
  'authSlice/setupAccount',
  async (
    payload: { token: string; email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const { token, email, password } = payload;
      const rs = await setupAccount({ token, email, password });
      return rs;
    } catch (error) {
      return rejectWithValue('Invalid email address or link');
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  'authSlice/resetPassword',
  async (
    payload: { token: string; email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const { token, email, password } = payload;
      const rs = await resetPassword({ token, email, password });
      return rs;
    } catch (error) {
      return rejectWithValue('Invalid email address or link');
    }
  }
);

export const loginThunk = createAsyncThunk(
  'authSlice/login',
  async (payload: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const { email, password } = payload;
      const response = await login({
        email,
        password,
      });
      if ('isVerified' in response && !response.isVerified) {
        return rejectWithValue('Unverified');
      }
      const { accessToken, profile, refreshToken } =
        response as IAuthenticationResponse;
      saveAuthToken({ refreshToken, accessToken });
      return profile;
    } catch (error) {
      return rejectWithValue('Incorrect email or password');
    }
  }
);

export const signUpThunk = createAsyncThunk(
  'authSlice/signup',
  async (payload: UserInfo, { rejectWithValue }) => {
    try {
      const response = await signup(payload);
      return response;
    } catch (error) {
      return rejectWithValue('Something went wrong. Please try again later');
    }
  }
);

export const loginSocialThunk = createAsyncThunk(
  'authSlice/loginWithSocial',
  async (
    payload: { email: string; sysType: string; userToken: string },
    { rejectWithValue, getState }
  ) => {
    try {
      const { email, sysType, userToken } = payload;
      const { refreshToken, accessToken, profile } = await loginWithSocial({
        email,
        sysType,
        userToken,
      });
      saveAuthToken({ refreshToken, accessToken });
      return profile;
    } catch (error) {
      return rejectWithValue('Incorrect account');
    }
  }
);

export const getUserProfileThunk = createAsyncThunk(
  'authSlice/getUserProfile',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getUserProfile();
      return data;
    } catch (error) {
      return rejectWithValue('Invalid token');
    }
  }
);

export const logoutThunk = createAsyncThunk(
  'authSlice/logout',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await logout();
      removeAuthToken();
      removeSocialUserData();
      return data;
    } catch (error) {
      return rejectWithValue('Invalid token');
    }
  }
);

export const signUpSocialThunk = createAsyncThunk(
  'authSlice/signUpSocialThunk',
  async (
    payload: {
      email: string;
      firstName: string;
      lastName: string;
      dob: string;
      phoneNumber: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const { authSlice } = getState() as RootState;
      const { email, firstName, lastName, dob, phoneNumber } = payload;

      const { refreshToken, accessToken, profile } = await signUpWithSocial({
        email,
        firstName,
        lastName,
        dob,
        phoneNumber,
        sysType: authSlice.sysType,
        userToken: authSlice.socialData!.userToken,
      });
      saveAuthToken({ refreshToken, accessToken });
      return profile;
    } catch (error) {
      return rejectWithValue('Incorrect account');
    }
  }
);

const initialState: {
  status: string;
  error?: string | null;
  isLoggedIn: boolean;
  userProfile: UserProfile | null;
  sysType: string;
  socialData?: SocialData | undefined;
  tempAccessToken?: string; // FOR PASSWORD FLOW
} = {
  status: LOADING_STATUS.IDLE,
  error: null,
  isLoggedIn: false,
  userProfile: null,
  sysType: AUTH_SYS_TYPE.PASSWORD,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    clearAuthStatusAndError: (state) => {
      state.status = LOADING_STATUS.IDLE;
      state.error = null;
    },

    updateSysType: (state, action: PayloadAction<IUpdateSysTypePayload>) => {
      state.sysType = action.payload.sysType;
      state.socialData = action.payload.socialData;
    },

    resetSysType: (state) => {
      state.sysType = AUTH_SYS_TYPE.PASSWORD;
      state.socialData = undefined;
    },

    updateLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },

    resetTempAccessToken: (state) => {
      state.tempAccessToken = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // Setup account
      .addCase(setupAccountThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(setupAccountThunk.fulfilled, (state) => {
        state.status = LOADING_STATUS.SUCCESS;
      })
      .addCase(setupAccountThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Reset password
      .addCase(resetPasswordThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.status = LOADING_STATUS.SUCCESS;
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Sign Up for social thunk
      .addCase(signUpSocialThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(signUpSocialThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = true;
        state.userProfile = action.payload;
      })
      .addCase(signUpSocialThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Login
      .addCase(loginThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = true;
        state.userProfile = action.payload;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // login with social
      .addCase(loginSocialThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(loginSocialThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = true;
        state.userProfile = action.payload;
      })
      .addCase(loginSocialThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Signup
      .addCase(signUpThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(signUpThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.userProfile = action.payload.profile;
        state.tempAccessToken = action.payload.accessToken;
      })
      .addCase(signUpThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Get user profile
      .addCase(getUserProfileThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
      })
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = true;
        state.userProfile = action.payload;
      })
      .addCase(getUserProfileThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = false;
        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      })
      // Logout
      .addCase(logoutThunk.pending, (state) => {
        state.status = LOADING_STATUS.LOADING;
        state.error = '';
        state.isLoggedIn = false;
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = false;
        state.userProfile = null;
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.status = LOADING_STATUS.SUCCESS;
        state.isLoggedIn = true;

        if (action.payload) {
          state.error = action.payload as string;
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const {
  clearAuthStatusAndError,
  updateLoginStatus,
  updateSysType,
  resetSysType,
  resetTempAccessToken,
} = authSlice.actions;
export default authSlice.reducer;
