import React from 'react';
import moment from 'moment';
import { CountryCode } from 'libphonenumber-js';

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIphoneIcon } from 'assets/icons/phone_iphone.svg';
import { ReactComponent as DobIcon } from 'assets/icons/dob.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/services.svg';
import { ReactComponent as TimelineIcon } from 'assets/icons/time-line.svg';
import { ReactComponent as PractitionerIcon } from 'assets/icons/practitioners.svg';
import { capitalizeAvatarName, concatFullName } from 'utils/common';
import {
  formatPhoneNumberWithCountryCode,
  geCountryByPhoneNumber,
} from 'utils/formatPhoneNumber';
import { Patient } from 'services/APIs';

import styles from './NewPatientCard.module.scss';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';

interface Props {
  patient: Patient;
}

const userInfo = [
  { icon: <MailIcon />, property: 'email' },
  { icon: <PhoneIphoneIcon />, property: 'phoneNumber' },
  { icon: <DobIcon />, property: 'dob' },
];

const futureApptInfo = [
  { icon: <ServiceIcon />, property: 'service' },
  { icon: <TimelineIcon />, property: 'appointmentDate' },
  { icon: <PractitionerIcon />, property: 'doctor' },
];

const NewPatientCard = ({ patient }: Props) => {
  const avatarText = capitalizeAvatarName(
    `${patient.firstName.replace(/\s/g, '').trim()} ${patient.lastName
      .replace(/\s/g, '')
      .trim()}`
  );

  const renderText = (item: { icon: any; property: string }) => {
    const property = item.property as keyof Patient;

    if (property === 'phoneNumber') {
      let value = patient[property];

      const countryCode = geCountryByPhoneNumber(value);

      value = formatPhoneNumberWithCountryCode(
        patient[property],
        countryCode as CountryCode
      );

      return value;
    }

    if (property === 'dob') {
      let value = patient[property];

      return value ? moment(value, 'YYYY-MM-DD').format('MMM DD, YYYY') : '';
    }

    if (property) return patient[property];
  };

  const renderFutureAppointmentInfo = (item: {
    icon: any;
    property: string;
  }) => {
    const appointment = patient.appointment;

    if (!appointment) return '';

    const property = item.property as keyof {
      id: string;
      appointmentDate: string;
      startTime: string;
      service: string;
      doctor: string;
    };

    if (property === 'appointmentDate') {
      const appointmentDateStr = moment(
        patient.appointment!.appointmentDate,
        'YYYY-MM-DD'
      ).format('MMM DD, YYYY');

      const startTime = moment(
        patient.appointment!.startTime,
        'HH:mm:ss'
      ).format('hh:mm A');

      return `${appointmentDateStr} - ${startTime}`;
    }

    if (property === 'service') {
      return appointment.service.includes(PATIENT_EXAM_AND_CLEANING)
        ? PATIENT_EXAM_AND_CLEANING
        : appointment.service;
    }

    return appointment[property];
  };

  const isInPast = patient?.appointment?.isInPast || false;

  return (
    <div className={styles['container']}>
      <div className={styles['left-section']}>
        <div className={styles['avatar']}>{avatarText}</div>

        <div className={styles['full-name']}>
          {concatFullName(patient.firstName, patient.lastName)}
        </div>
      </div>
      <div className={styles['right-section']}>
        <div className={styles['patient-info-section']}>
          <div className={styles['bold']}>Patient's Info</div>
          {userInfo.map((item) => (
            <div key={item.property} className={styles['row']}>
              <span>{item.icon}</span>
              <span>{renderText(item)}</span>
            </div>
          ))}
        </div>

        <div className={styles['future-appt-section']}>
          <div className={styles['bold']}>
            {isInPast ? 'Incomplete Appointment' : 'Future Appointment'}
          </div>
          {futureApptInfo.map((item) => (
            <div key={item.property} className={styles['row']}>
              <span>{item.icon}</span>
              <span>{renderFutureAppointmentInfo(item)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewPatientCard;
