import moment from 'moment';

export const MINUTE_OF_A_BLOCK = 5;

export const convertTime = (minutes: number) => {
  const duration = moment.duration(minutes, 'minutes');
  const hour = duration.hours();
  const minute = duration.minutes();

  return moment(`${hour}:${minute}`, 'H:m').format('hh:mm A');
};

export const convertTimeHHmmss = (minutes: number) => {
  const duration = moment.duration(minutes, 'minutes');
  const hour = duration.hours();
  const minute = duration.minutes();
  const second = duration.seconds();

  return moment(`${hour}:${minute}:${second}`, 'H:m:s').format('HH:mm:ss');
};

export const convertTimeToMinutes = (time: string) =>
  moment.duration(time).asMinutes();

export const calculateOrdinalBlocks = (timeInMinute: number) => {
  return Math.floor(timeInMinute / MINUTE_OF_A_BLOCK);
};
