import React, { FC, useState } from 'react';
import styles from './NewPatient.module.scss';
import cx from 'classnames';
import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
} from 'utils/inputValidation';
import { useForm } from 'react-hook-form';
import NavigationBtnControl from 'components/BookingForm/NavigationBtnControl/NavigationBtnControl';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import {
  popStep,
  pushStep,
  STEPS,
  updateStep2,
  updateStep3,
} from 'redux/bookingFormSlice';
import { batch } from 'react-redux';
import FormTextInput from 'components/BookingForm/FormTextInput/FormTextInput';
import GoogleButton from 'components/BookingForm/SocialButton/GoogleButton';
import FacebookButton from 'components/BookingForm/SocialButton/FacebookButton';
import { UserCredential } from 'firebase/auth';
import { loginSocialThunk, updateSysType } from 'redux/authSlice';
import { checkEmail } from 'services/APIs';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { getUserEmailFromSocial } from 'components/BookingForm/utils/getOAuthData';
import { AUTH_SYS_TYPE, PROVIDER_ID } from 'utils/constants/common';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import forwardEvent, { EVENT } from 'utils/tracking/forwardEventTracking';
import { formatDobForBE } from 'components/BookingForm/utils/helperFunction';

interface NewPatientProps {
  resetForm: () => void;
}

const NewPatient: FC<NewPatientProps> = ({ resetForm }) => {
  const {
    bookingFormSlice: {
      step2: { newPatient },
      pageStack,
      step1: { phoneNumber, dob },
      step3: { patients },
    },
    authSlice: { socialData },
    clinicDetailsSlice: {
      clinicData: { googleTagManagerId, website },
    },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles.container]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const formSectionClassName = cx({
    [styles['form-section']]: true,
    [styles['form-section-iframe']]: isLoadedInsideIframe,
  });

  const nameSectionClassName = cx({
    [styles['name-section']]: true,
    [styles['name-section-iframe']]: isLoadedInsideIframe,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { control, getValues, formState } = useForm({
    mode: 'all',
    defaultValues: newPatient,
  });

  const handleOnBackClick = () => {
    const previousPage = pageStack[pageStack.length - 2];

    batch(() => {
      dispatch(popStep());

      dispatch(
        updateStep2({
          newPatient: {
            ...newPatient,
            isFromPatientInfo:
              previousPage === STEPS.PATIENT_INFO ? true : false,
          },
        })
      );
    });
  };

  const handleOnNextClick = () => {
    const isPreviousPageReview =
      pageStack[pageStack.length - 2] === STEPS.REVIEW;

    const email = getValues('email').trim();
    const firstName = getValues('firstName').trim();
    const lastName = getValues('lastName').trim();

    const patientInfo: any = {
      email,
      firstName,
      lastName,
      dob: formatDobForBE(dob),
      phoneNumber,
      isNewPatient: true,
    };

    let nextStep = STEPS.NOTE;

    const duplicateNewPatient =
      isPreviousPageReview &&
      patients.find(
        (patientItem) =>
          patientItem.email?.toLowerCase() ===
            patientInfo.email?.toLowerCase() &&
          patientItem.firstName?.toLowerCase() ===
            patientInfo.firstName.toLowerCase() &&
          patientItem.lastName?.toLowerCase() ===
            patientInfo.lastName.toLowerCase()
      );

    if (duplicateNewPatient) {
      patientInfo.id = duplicateNewPatient.id;
      nextStep = duplicateNewPatient.isBookable ? STEPS.NOTE : STEPS.REVIEW;
    }

    batch(() => {
      dispatch(
        updateStep2({
          newPatient: { email, firstName, lastName },
        })
      );
      dispatch(updateStep3({ patientInfo: { ...patientInfo, note: '' } }));
      dispatch(pushStep(nextStep));
    });

    forwardEvent({
      isLoadedInsideIframe,
      googleTagManagerId,
      website,
      payload: { event: EVENT.PERSONAL_DETAIL_FILLED },
    });
  };

  const handleSocialLogin = async (
    userCredential: UserCredential,
    sysType: string
  ) => {
    const user = userCredential.user;

    const providerId =
      sysType === AUTH_SYS_TYPE.GOOGLE
        ? PROVIDER_ID.GOOGLE
        : PROVIDER_ID.FACEBOOK;

    const email = getUserEmailFromSocial(userCredential, providerId);

    setIsLoading(true);
    const userToken = await user.getIdToken();

    const { data } = await checkEmail(email);
    setIsLoading(false);
    if (data?.isExistingEmail) {
      return dispatch(loginSocialThunk({ email, sysType, userToken }));
    }

    const [firstName, lastName] = user.displayName!.split(' ');
    const userData = {
      email,
      firstName,
      lastName,
    };
    const socialData = { ...userData, userToken };

    batch(() => {
      dispatch(
        updateStep2({
          newPatient: userData,
        })
      );
      dispatch(updateSysType({ socialData, sysType }));
    });

    resetForm();
  };

  return (
    <form className={containerClassName} noValidate>
      {isLoading && <LoadingScreen />}
      <div className={styles['otp-form']}>
        <div className={`${styles['bold']} ${styles['title']}`}>
          Just a few details
        </div>
        <div className={styles['section']}>
          <div>Please sign up with</div>
          <div className={styles['social-section']}>
            <GoogleButton onSuccess={handleSocialLogin} />
            <FacebookButton onSuccess={handleSocialLogin} />
          </div>
          <div className={styles['fill-section']}>or fill the form</div>
        </div>
        <div className={formSectionClassName}>
          <FormTextInput
            type="text"
            name="email"
            label="Email address"
            control={control}
            placeholder="Input your email address"
            required="This field is required"
            rules={{
              validate: (value: string) => {
                if (!emailValidation(value)) {
                  return 'Invalid email address';
                }
              },
            }}
            disabled={!!socialData?.email}
          />
          <div className={nameSectionClassName}>
            <FormTextInput
              type="text"
              name="firstName"
              label="First Name"
              control={control}
              placeholder="Input your first name"
              required="This field is required"
              rules={{
                validate: (value: string) => {
                  const message = firstNameValidation(value);
                  return message || true;
                },
              }}
            />
            <FormTextInput
              type="text"
              name="lastName"
              label="Last Name"
              control={control}
              placeholder="Input your last name"
              required="This field is required"
              rules={{
                validate: (value: string) => {
                  const message = lastNameValidation(value);
                  return message || true;
                },
              }}
            />
          </div>
        </div>
      </div>
      <NavigationBtnControl
        onBack={handleOnBackClick}
        onNext={handleOnNextClick}
        isDisabledNextBtn={!formState.isValid}
      />
    </form>
  );
};

export default NewPatient;
