import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { sortBy } from 'lodash';
import { Patient } from 'services/APIs';
import { getGeocodeByPlaceDetails } from 'services/APIs/getGeocodeByPlaceDetails';
import { GOOGLE_GEO_LOCATION_API } from 'utils/constants/common';

export const DEFAULT_COUNTRY_CODE = 'ca';

export const STEPS = {
  PATIENT_INFO: '1.PATIENT_INFO',
  VERIFICATION: '2.VERIFICATION',
  SIGN_IN: '2.SIGN_IN',
  SIGN_UP: '2.SIGN_UP',
  NEW_PATIENT: '2.NEW_PATIENT',
  FORGET_PASSWORD: '2.FORGET_PASSWORD',
  REVIEW: '3.REVIEW',
  NOTE: '3.NOTE',
};

interface Step1 {
  phoneNumber: string;
  dob: string;
  countryCode: string;
}

interface Step2 {
  newPatient: NewPatient;
  signUp: SignUp;
}

export interface SignUp {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  phoneNumber: string;
  countryCode: string;
  password: string;
}

interface NewPatient {
  firstName: string;
  lastName: string;
  email: string;
  isFromPatientInfo?: boolean;
}

interface Step3 {
  patients: Patient[];
  chosenPatientId: string;
  patientInfo: {
    id?: string;
    dob: string;
    email: string;
    firstName: string;
    isNewPatient: boolean;
    lastName: string;
    phoneNumber: string;
    note: string;
  } | null;
}

interface BookingFormState {
  cachedData: Omit<Step1, 'countryCode'>;
  step1: Step1;
  step2: Step2;
  step3: Step3;
  pageStack: string[];
  isDone: boolean;
  isKickedFromStep3: boolean;
}

const getCountryCodeByIP = async () => {
  try {
    const {
      data: { location },
    } = await axios.post(
      `${GOOGLE_GEO_LOCATION_API}?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    );

    const results = await getGeocodeByPlaceDetails(location.lat, location.lng);

    if (results.length !== 0) {
      const countryCode =
        results[0].address_components.find((item: any) =>
          item.types.includes('country')
        )?.short_name || DEFAULT_COUNTRY_CODE;

      return countryCode?.toLowerCase();
    }

    return DEFAULT_COUNTRY_CODE;
  } catch (error) {
    return DEFAULT_COUNTRY_CODE;
  }
};

export const getCountryCodeByIPThunk = createAsyncThunk(
  'bookingFormSlice/getCountryCodeByIP',
  async () => {
    const rs = await getCountryCodeByIP();
    return rs;
  }
);

const initialState: BookingFormState = {
  cachedData: {
    phoneNumber: '',
    dob: '',
  },
  step1: {
    phoneNumber: '',
    dob: '',
    countryCode: DEFAULT_COUNTRY_CODE,
  },
  step2: {
    newPatient: {
      firstName: '',
      lastName: '',
      email: '',
      isFromPatientInfo: false,
    },
    signUp: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      phoneNumber: '',
      countryCode: DEFAULT_COUNTRY_CODE,
      password: '',
    },
  },
  step3: {
    patients: [],
    chosenPatientId: '',
    patientInfo: null,
  },

  pageStack: [STEPS.PATIENT_INFO],
  isDone: false,
  isKickedFromStep3: false,
};

const bookingFormSlice = createSlice({
  name: 'bookingForm',
  initialState,
  reducers: {
    resetBookingForm: (state) => {
      state.step1 = {
        ...initialState.step1,
        countryCode: state.step1.countryCode,
      };

      state.step2 = {
        newPatient: {
          ...initialState.step2.newPatient,
        },
        signUp: {
          ...initialState.step2.signUp,
          countryCode: state.step2.signUp.countryCode,
        },
      };

      state.step3 = initialState.step3;
      state.cachedData = initialState.cachedData;
      state.pageStack = initialState.pageStack;
      state.isDone = initialState.isDone;
    },
    updateStep1: (state, action: PayloadAction<Partial<Step1>>) => {
      state.step1 = { ...state.step1, ...action.payload };
    },
    updateStep2: (state, action: PayloadAction<Partial<Step2>>) => {
      if (action.payload.newPatient) {
        state.step2.newPatient = action.payload.newPatient;
      }
      if (action.payload.signUp) {
        state.step2.signUp = {
          ...state.step2.signUp,
          ...action.payload.signUp,
        };
      }
    },
    resetStep2SignUp: (state) => {
      state.step2.signUp = {
        ...initialState.step2.signUp,
        countryCode: state.step2.signUp.countryCode,
      };
    },
    resetStep2NewPatient: (state) => {
      state.step2.newPatient = initialState.step2.newPatient;
    },
    updateStep3: (state, action: PayloadAction<Partial<Step3>>) => {
      if (action.payload.patients) {
        const sortedPatients = sortBy(action.payload.patients, 'name');

        const bookablePatients = sortedPatients.filter(
          (item) => item.isBookable
        );

        state.step3.chosenPatientId = bookablePatients[0]?.id;

        state.step3.patients = sortedPatients;
      }
      if (action.payload.chosenPatientId) {
        state.step3.chosenPatientId = action.payload.chosenPatientId;
      }
      if (action.payload.patientInfo) {
        state.step3.patientInfo = action.payload.patientInfo;
      }
    },
    updateCached: (
      state,
      action: PayloadAction<Omit<Step1, 'countryCode'>>
    ) => {
      state.cachedData = action.payload;
    },
    resetCached: (state) => {
      state.cachedData = initialState.cachedData;
    },
    updateStep: (state, action: PayloadAction<string[]>) => {
      state.pageStack = action.payload;
    },
    popStep: (state) => {
      state.pageStack.pop();
    },
    pushStep: (state, action: PayloadAction<string>) => {
      const pageStack = state.pageStack;
      const currentPage = pageStack[pageStack.length - 1];
      if (currentPage === STEPS.VERIFICATION) {
        pageStack.pop();
      }
      pageStack.push(action.payload);
    },
    replaceStep: (state, action: PayloadAction<string>) => {
      const pageStack = state.pageStack;
      pageStack[pageStack.length - 1] = action.payload;
    },
    updateIsDone: (state) => {
      state.isDone = true;
    },
    updateIsKickedFromStep3: (state) => {
      state.isKickedFromStep3 = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get CountryCodeByIP
      .addCase(getCountryCodeByIPThunk.fulfilled, (state, action) => {
        state.step1.countryCode = action.payload;
        state.step2.signUp.countryCode = action.payload;
      });
  },
});

export const {
  resetBookingForm,
  updateStep1,
  updateStep2,
  resetStep2SignUp,
  resetStep2NewPatient,
  updateStep3,
  updateCached,
  resetCached,
  updateStep,
  popStep,
  pushStep,
  replaceStep,
  updateIsDone,
  updateIsKickedFromStep3,
} = bookingFormSlice.actions;

export default bookingFormSlice.reducer;
