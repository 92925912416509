import React, { FC, useEffect, useState } from 'react';
import { batch } from 'react-redux';

import styles from './BookingForm.module.scss';
import { Header } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';
import cx from 'classnames';
import {
  resetBookingForm,
  STEPS,
  updateStep,
  updateStep2,
  updateStep3,
} from 'redux/bookingFormSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import { getPatientsAPI } from 'services/APIs';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import BookingConfirmation from './BookingConfirmation/BookingConfirmation';
import ProgressBar from '../ProgressBar/ProgressBar';

import {
  convertTimeHHmmss,
  MINUTE_OF_A_BLOCK,
} from 'utils/convertMinutesIntoHoursOfDay';
import { loadOtpToken } from 'utils/storage';
import { resetSysType, resetTempAccessToken } from 'redux/authSlice';
import { geCountryByPhoneNumber } from 'utils/formatPhoneNumber';
import { Dialog } from '@material-ui/core';
import PoweredBySection from 'components/PoweredBySection/PoweredBySection';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import { AUTH_SYS_TYPE } from 'utils/constants/common';
import { ChairOptimization } from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import BookingStep from './BookingStep/BookingStep';

interface BookingFormProps {
  date: string;
  timeBlock: number;
  clinicId: string;
  serviceId: string;
  practitionerId: string;
  operatoryId?: string;
  bookingSummaryInfo: {
    avatar: string;
    title: string;
    serviceTitle: string;
    clinicName: string;
    clinicAddress: string;
    clinicEmail: string;
    clinicPhoneNumber: string;
  };
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
  isPreferredDoctor?: boolean;
  closeBookingDialog: () => void;
}

const { FORGET_PASSWORD } = STEPS;

const PAGES_INFO = [
  { page: 1, content: 'User Info', mobileContent: 'Patient' },
  { page: 2, content: 'Verify', mobileContent: 'Verify' },
  { page: 3, content: 'Review', mobileContent: 'Review' },
];

const BookingForm: FC<BookingFormProps> = (props) => {
  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const contentContainerClassName = cx({
    [styles['content-container']]: true,
    [styles['content-container-iframe']]: isLoadedInsideIframe,
  });

  const {
    bookingFormSlice: { pageStack, isDone, step1 },
    authSlice: { isLoggedIn, userProfile, sysType },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const currentPage = pageStack[pageStack.length - 1];
  const pageNumber = parseInt(currentPage.split('.')[0]);

  const [isLoading, setIsLoading] = useState(false);

  const startTime = convertTimeHHmmss(props.timeBlock * MINUTE_OF_A_BLOCK);
  const appointmentDate = props.date;

  const onClose = () => {
    batch(() => {
      dispatch(resetBookingForm());
      dispatch(resetSysType());
      dispatch(resetTempAccessToken());
    });
    props.closeBookingDialog();
  };

  useEffect(() => {
    const getPatients = async () => {
      if (
        !isLoggedIn ||
        currentPage === STEPS.REVIEW ||
        currentPage === STEPS.NOTE
      ) {
        return;
      }

      dispatch(updateStep([STEPS.REVIEW]));
      setIsLoading(true);

      const patients = await getPatientsAPI(
        {
          clinicId: props.clinicId,
          serviceId: props.serviceId,
          dob: userProfile?.dob,
          phoneNumber: userProfile?.phoneNumber,
          startTime,
          appointmentDate,
        },
        { otpToken: loadOtpToken() }
      );

      if (patients.length === 0 && userProfile) {
        dispatch(
          updateStep2({
            signUp: {
              email: userProfile.email,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              dob: userProfile.dob,
              phoneNumber: userProfile.phoneNumber,
              countryCode: geCountryByPhoneNumber(userProfile.phoneNumber),
              password: '',
            },
          })
        );
      }

      if (patients.length > 0) {
        dispatch(updateStep3({ patients }));
      }
      setIsLoading(false);
    };

    getPatients();
  }, [
    appointmentDate,
    currentPage,
    dispatch,
    isLoggedIn,
    props.clinicId,
    props.serviceId,
    startTime,
    userProfile,
  ]);

  if (isLoadedInsideIframe) {
    return (
      <div className={containerClassName}>
        {isLoading && <LoadingScreen />}
        <div className={contentContainerClassName}>
          {isDone ? (
            <BookingConfirmation />
          ) : (
            <BookingStep {...props} currentPage={currentPage} />
          )}
        </div>
        <PoweredBySection />
      </div>
    );
  }

  return (
    <Dialog open={!!props.date} onClose={onClose} fullScreen>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header hasBoxShadow>
            <NavigationBar>
              {!isDone && ![FORGET_PASSWORD].includes(currentPage) && (
                <ProgressBar page={pageNumber} options={PAGES_INFO} />
              )}
            </NavigationBar>
          </Header>
        </div>
        {isLoading && <LoadingScreen />}
        <div className={styles['content-container']}>
          {isDone ? (
            <BookingConfirmation
              isMessageDisplayed={!step1.phoneNumber && pageStack.length > 1}
              isSocialLoggedIn={sysType !== AUTH_SYS_TYPE.PASSWORD}
            />
          ) : (
            <BookingStep {...props} currentPage={currentPage} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default BookingForm;
